export const plans = [
  {
    title: 'Brick Oven Fridays',
    desc: 'Each Friday we offer the best brick oven pizzas with special discounts!',
    author: 'Chef Deming',
    date: '30 min ago',
    location: 'New York, USA',
    img: '/img/brick-oven-discount.webp',
  },
  {
    title: 'New Pizzas Coming Soon',
    desc: 'Get ready for fresh and exciting brick oven pizza varieties starting Monday!',
    author: 'Belissimo Arthur',
    date: '2 days ago',
    location: 'Seoul, Korea',
    img: '/img/new-brick-oven-pizzas.webp',
  },
  {
    title: 'Grand Opening in Florida',
    desc: 'Join us for the grand opening of our new brick oven pizza restaurant in Florida!',
    author: 'Morgan News',
    date: '5 days ago',
    location: 'Florida, USA',
    img: '/img/brick-oven-opening.webp',
  },
  {
    title: 'Follow Us on SNS',
    desc: '🍕 Discover our healthy and delicious brick oven pizza recipes on SNS!',
    author: 'Belissimo Agent',
    date: 'A week ago',
    location: 'France, Europe',
    img: '/img/brick-oven-sns.webp',
    link: 'https://www.instagram.com/brickovennypizza/',
  },
];
