import {
  Container,
  Stack,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Basket from './Basket';
import { CartItem } from '../../../lib/types/search';
import { useGlobals } from '../../hooks/useGlobals';
import { serverApi } from '../../../lib/config';
import { Logout } from '@mui/icons-material';

interface OtherNavbarProps {
  cartItems: CartItem[];
  onAdd: (item: CartItem) => void;
  onDelete: (item: CartItem) => void;
  onRemove: (item: CartItem) => void;
  onDeleteAll: () => void;
  setSignupOpen: (isOpen: boolean) => void;
  setLoginOpen: (isOpen: boolean) => void;
  handleLogoutClick: (e: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  handleCloseLogout: () => void;
  handleLogoutRequest: () => void;
}

export default function OtherNavbar(props: OtherNavbarProps) {
  const {
    cartItems,
    onAdd,
    onDelete,
    onDeleteAll,
    onRemove,
    setSignupOpen,
    setLoginOpen,
    handleLogoutClick,
    anchorEl,
    handleCloseLogout,
    handleLogoutRequest,
  } = props;
  const { authMember } = useGlobals();

  return (
    <div className="other-navbar">
      <Container className="navbar-container">
        <Stack className="menu">
          <Box className={'hoover-line'}>
            <NavLink to="/">
              <img className="brand-logo" src="/icons/brickoven.png" />
            </NavLink>
          </Box>
          <Stack className="links">
            <Box className={'hoover-line'}>
              <NavLink to="/">Home</NavLink>
            </Box>
            <Box className={'hoover-line'}>
              <NavLink to="/products" activeClassName="underline">
                Products
              </NavLink>
            </Box>
            {authMember ? (
              <Box className={'hoover-line'}>
                <NavLink to="/orders" activeClassName="underline">
                  Orders
                </NavLink>
              </Box>
            ) : null}
            {authMember ? (
              <Box className={'hoover-line'}>
                <NavLink to="/member-page" activeClassName="underline">
                  MyPage
                </NavLink>
              </Box>
            ) : null}
            <Box className={'hoover-line'}>
              <NavLink to="/help" activeClassName="underline">
                Help
              </NavLink>
            </Box>
            <Basket
              cartItems={cartItems}
              onAdd={onAdd}
              onRemove={onRemove}
              onDelete={onDelete}
              onDeleteAll={onDeleteAll}
            />
            {!authMember ? (
              <Box>
                <Button
                  onClick={() => setLoginOpen(true)}
                  variant="contained"
                  className="login-button"
                >
                  Login
                </Button>
              </Box>
            ) : (
              <img
                className="user-avatar"
                src={
                  authMember?.memberImage
                    ? `${serverApi}/${authMember.memberImage}`
                    : '/icons/default-user.svg'
                }
                aria-haspopup={'true'}
                onClick={handleLogoutClick}
              />
            )}

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={handleCloseLogout}
              onClick={handleCloseLogout}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleLogoutRequest}>
                <ListItemIcon>
                  <Logout fontSize="small" style={{ color: 'blue' }} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
        <Stack className="header-frame">
          <Stack className="detail">
            {/* <Box className="head-main-txt">
                            World's Most Delicious Cousine
                        </Box>
                        <Box className="welcome-txt">
                            The Choice, not just a choice
                        </Box>
                        <Box className="service-txt">24 hours service</Box> */}
            {/* <Box className="signup">
                            {!authMember ? (
                                <Button
                                    variant="contained"
                                    className="signup-button"
                                >
                                    SIGN UP
                                </Button>
                            ) : null}
                        </Box> */}
          </Stack>
          <Stack className="logo-frame">
            <div className="logo-image"></div>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
}
